body {
  margin-top: 100px;
  height: 100vh;
}
.container {
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.content {
  display: flex;
  transition: transform 0.3s ease;
}

.item {
  flex-shrink: 0;
  width: 50%;
  margin: 0 10%;
  padding: 10rem 0;
  border-radius: 5px;
  text-align: center;
}

.nav {
  margin-top: 30px;
  display: flex;
  justify-content: space-around;
}
